<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
    <v-container>
        <v-row class="align-center d-flex h-0 pb-20">
            <v-col xl="3" lg="4" sm="9" class="mx-auto" style="text-align: center;">

                <v-card class="bg-orange">
                    <span class="text-purple">สแกนเพื่อทำแบบบันทึกประวัติสุขภาพผู้ป่วย</span>
                </v-card>
                <br />

                <v-row class="">
                    <v-col cols="12" style="text-align: center;">

                        <div>
                            <p class="error">{{ error }}</p>

                            <!-- <p class="decode-result">Last result: <b>{{ result }}</b></p> -->
                            <v-card height="300">
                                <v-card-text>
                                    <qrcode-stream @decode="onDecode" @init="onInit" />
                                    <br>
                                    <span>สแกน QR ผู้ป่วย</span>
                                </v-card-text>
                            </v-card>

                        </div>

                    </v-col>
                </v-row>

            </v-col>
        </v-row>
        <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="left" style="padding-top: 15px">
            <v-col xl="3" lg="4" sm="9" class="mx-auto" style="text-align: left;">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <!-- <v-btn @click="$router.push('/member_patient_health_add_list')" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">

                    เลือกจากรายชื่อ
                </v-btn> -->
            </v-col>

        </v-row>

        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-left" justify="left">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <!-- <v-btn @click="$router.push('/member_patient_health_add_list')" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">

                    เลือกจากรายชื่อ
                </v-btn> -->
            </v-row>
        </v-footer>
    </v-container>
</div>
</template>

<script>
import WaitingModal from "@/components/WaitingModal.vue";
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import {
    QrcodeStream
} from 'vue-qrcode-reader'
export default {
    name: "calendar-patient",
    components: {
        QrcodeStream,
        WaitingModal
    },
    data: () => ({
        isValid: undefined,
        camera: 'auto',
        result: null,
        token: '',
        size: 100,
        active_waiting: false,
        status: '',
        alert_name: '',

    }),

    mounted() {

    },
    watch: {
        result(val) {
            if (val.length > 0) {
                this.submitVal()
            }
        }
    },
    computed: {
        validationPending() {
            return this.isValid === undefined &&
                this.camera === 'off'
        },

        validationSuccess() {
            return this.isValid === true
        },

        validationFailure() {
            return this.isValid === false
        },
    },

    methods: {

        update_status_wait(val) {
            this.active_waiting = val
        },
        onDecode(result) {
            this.result = result
        },
        handleClick(value) {
            this.selected_row = value;
            this.dialog_edit = true;
        },
        edit_data(alert) {
            this.edit_aleart = alert;
            this.dialog_edit = true;
        },
        async submitVal() {
            this.active_waiting = true
            this.title = '',
                this.status = 'loading'
            var user_patient_id = this.result.split("/")[1]
            await Vue.axios.post(APIURL + '/active_patient_qr', {
                "user_scanner_id": this.$store.state.login_detail.user_id,
                "current_qr": user_patient_id,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log(response)
                if (response.data.detail.status == 'this_qr_activated') {
                    this.title = 'QR code ถูกต้อง';
                    this.status = 'done'
                    setTimeout(() => {
                        this.active_waiting = false
                        this.$store.commit('setQrPatientId', ('2' + user_patient_id).slice(0, 7))
                        this.$store.commit('setQrPatientInfo', response.data.detail.data)
                        this.$router.push('/view_patient_health_record_add')
                    }, 2000)

                } else if (response.data.detail.status == 'this_qr_not_correct') {
                    this.active_waiting = true
                    this.title = 'กรุณาให้ผู้ป่วยสร้าง QR ใหม่<br>และลองใหม่อีกครั้ง',
                        this.status = 'fail'
                } else {
                    this.active_waiting = true
                    this.title = 'ระบบขัดข้อง',
                        this.status = 'fail'
                }

            })

        },
        async onInit(promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permission"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                    this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                }
            }
        }
    },

};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 0px;
}

.qr-code {
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    padding-top: 12px;
    width: 225px;
    display: inline-block;
}

.validation-success,
.validation-failure,
.validation-pending {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, .8);
    text-align: center;
    font-weight: bold;
    font-size: 1.4rem;
    padding: 10px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.validation-success {
    color: green;
}

.validation-failure {
    color: red;
}
</style>
